"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fade = exports.swipe = exports.rollLeft = exports.rollRight = void 0;
exports.rollRight = {
    hidden: {
        x: '-60px',
        opacity: 0
    },
    visible: {
        x: '0',
        opacity: 1
    },
    exit: {
        x: '100px',
        opacity: 0
    }
};
exports.rollLeft = {
    hidden: {
        x: '100px',
        opacity: 0
    },
    visible: {
        x: '0',
        opacity: 1
    },
    exit: {
        x: '-60px',
        opacity: 0
    }
};
exports.swipe = {
    default: {
        x: 0,
        y: 0,
        opacity: 1
    },
    up: {
        y: '-30px',
        opacity: 0
    },
    down: {
        y: '30px',
        opacity: 0
    },
    left: {
        x: '-70px',
        opacity: 0
    },
    right: {
        x: '70px',
        opacity: 0
    }
};
exports.fade = {
    default: {
        opacity: 1
    },
    hidden: {
        opacity: 0
    }
};
