"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logInteraction = exports.ActionEvent = exports.OptionAttribute = void 0;
var OptionAttribute;
(function (OptionAttribute) {
    OptionAttribute["MODEL_SELECTED"] = "Truck Model Options";
    OptionAttribute["NAVIGATION_INTERACTION"] = "Configurator Steps";
    OptionAttribute["CHANGED_ENVIRONMENT"] = "Environment Options";
    OptionAttribute["EXTERIOR_TRIM_SELECTED"] = "Exterior Trim Options";
    OptionAttribute["COLOR_SELECTED"] = "Exterior Color Options";
    OptionAttribute["CHASSIS_FAIRING_SELECTED"] = "Chassis Fairing Finish";
    OptionAttribute["ROOF_FAIRING_SELECTED"] = "Roof Fairing Options";
    OptionAttribute["REAR_WHEEL_AERODYNAMICS_SELECTED"] = "Wheel Aerodynamics Options";
    OptionAttribute["CMS_SELECTED"] = "Camera Monitoring System Options";
    OptionAttribute["INTERIOR_TRIM_SELECTED"] = "Interior Trim Options";
    OptionAttribute["SEAT_SELECTED"] = "Seat Options";
    OptionAttribute["BED_OPTION_SELECTED"] = "Bunk Options";
    OptionAttribute["UPPER_BACK_WALL_SELECTED"] = "Upper Back Wall Options";
    OptionAttribute["ENGINE_SELECTED"] = "Engine Options";
    OptionAttribute["TRANSMISSION_SELECTED"] = "Transmission Options";
    OptionAttribute["POWERTRAIN_PACKAGE_SELECTED"] = "Powertrain Package Options";
    OptionAttribute["AXLE_CONFIGURATION_SELECTED"] = "Axle Configuration Options";
    OptionAttribute["AMENITIES_PACKAGE_SELECTED"] = "Amenities Package Options";
    OptionAttribute["TECH_PACKAGE_SELECTED"] = "Technology Package Options";
})(OptionAttribute = exports.OptionAttribute || (exports.OptionAttribute = {}));
var ActionEvent;
(function (ActionEvent) {
    ActionEvent["MODEL_PAGE_LOADED"] = "Viewed Model Selection Page";
    ActionEvent["OPENED_MODEL_COMPARISON"] = "Clicked Compare Models";
    ActionEvent["MODEL_SELECTED"] = "Select Model";
    ActionEvent["EXTERIOR_TRIM_PAGE_LOADED"] = "Viewed Exterior Trim Page";
    ActionEvent["EXTERIOR_TRIM_SELECTED"] = "Select Exterior Trim";
    ActionEvent["COLOR_PAGE_LOADED"] = "Viewed Exterior Color Page";
    ActionEvent["COLOR_SELECTED"] = "Exterior Color Selected";
    ActionEvent["CHASSIS_FAIRING_PAGE_LOADED"] = "Exterior Finishes Page Loaded";
    ActionEvent["CHASSIS_FAIRING_SELECTED"] = "Select Exterior Finishes";
    ActionEvent["ROOF_FAIRING_PAGE_LOADED"] = "Viewed Roof Fairings Page";
    ActionEvent["ROOF_FAIRING_SELECTED"] = "Select Roof Fairings";
    ActionEvent["REAR_WHEEL_AERODYNAMICS_PAGE_LOADED"] = "Viewed Wheel Aerodynamics Page";
    ActionEvent["REAR_WHEEL_AERODYNAMICS_SELECTED"] = "Select Wheel Aerodynamics";
    ActionEvent["CMS_PAGE_LOADED"] = "Viewed Camera Monitoring System Page";
    ActionEvent["CMS_SELECTED"] = "Select Camera Monitoring System";
    ActionEvent["INTERIOR_TRIM_PAGE_LOADED"] = "Viewed Interior Trim Level Page";
    ActionEvent["INTERIOR_TRIM_SELECTED"] = "Select Interior Trim";
    ActionEvent["SEATS_PAGE_LOADED"] = "Viewed Seats Page";
    ActionEvent["SEAT_SELECTED"] = "Select Seat";
    ActionEvent["BED_OPTION_PAGE_LOADED"] = "Viewed Bed Option Page";
    ActionEvent["BED_OPTION_SELECTED"] = "Select Bunk";
    ActionEvent["UPPER_BACK_WALL_PAGE_LOADED"] = "Viewed Upper Back Wall Page";
    ActionEvent["UPPER_BACK_WALL_SELECTED"] = "Select Upper Back Wall";
    ActionEvent["ENGINE_PAGE_LOADED"] = "Viewed Engine Page";
    ActionEvent["ENGINE_SELECTED"] = "Select Engine";
    ActionEvent["TRANSMISSION_PAGE_LOADED"] = "Viewed Transmission Page";
    ActionEvent["TRANSMISSION_SELECTED"] = "Select Transmission";
    ActionEvent["POWERTRAIN_PACKAGES_PAGE_LOADED"] = "Viewed Powertrain Packages Page";
    ActionEvent["POWERTRAIN_PACKAGE_SELECTED"] = "Select Powertrain Package";
    ActionEvent["AXLE_CONFIGURATION_PAGE_LOADED"] = "Viewed Axle Configuration Page";
    ActionEvent["AXLE_CONFIGURATION_SELECTED"] = "Select Axle Configuration";
    ActionEvent["AMENITIES_PACKAGE_PAGE_LOADED"] = "Viewed Amenities Package Page";
    ActionEvent["AMENITIES_PACKAGE_SELECTED"] = "Select Amenities Package";
    // ACTIVE_SAFETY_PLATFORM_PAGE_LOADED = 'Viewed Active Safety Platform Page',
    // ACTIVE_SAFETY_PLATFORM_SELECTED = 'Select Active Safety Platform',
    // DRIVER_SAFETY_PACKAGE_PAGE_LOADED = 'Driver Safety Package Page',
    // DRIVER_SAFETY_PACKAGE_SELECTED = 'Select Driver Safety Package',
    ActionEvent["TECH_PACKAGE_PAGE_LOADED"] = "Viewed Technology Package Page";
    ActionEvent["TECH_PACKAGE_SELECTED"] = "Select Technology Package";
    // CONNECTIVITY_PACKAGE_PAGE_LOADED = 'Viewed Connectivity Package Page',
    // CONNECTIVITY_PACKAGE_SELECTED = 'Select Connectivity Package',
    ActionEvent["OPENED_PACKAGE_COMPARISON_MODAL"] = "Clicked Compare Packages";
    ActionEvent["OPENED_DETAILS_MODAL"] = "Clicked View Details";
    ActionEvent["ACTIVATED_FULL_SCREEN_MODE"] = "Clicked Full Screen Mode";
    ActionEvent["CHANGED_ENVIRONMENT"] = "Clicked Environment Selector";
    ActionEvent["NAVIGATION_INTERACTION"] = "Clicked Navigation Step";
})(ActionEvent = exports.ActionEvent || (exports.ActionEvent = {}));
var logInteraction = function (event) {
    var _a;
    var dataLayerEvent = {
        event: 'applicationInteraction',
        _volvo: {
            universal: {
                web: {
                    application: __assign(__assign({ name: 'Volvo Trucks North America' }, event), { applicationInteraction: { value: 1 } })
                }
            }
        },
        web: {
            webInteraction: {
                type: 'other'
            }
        }
    };
    console.log('Event:', dataLayerEvent);
    (_a = window.adobeDataLayer) === null || _a === void 0 ? void 0 : _a.push(dataLayerEvent);
};
exports.logInteraction = logInteraction;
