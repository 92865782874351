"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateLastReachedStepInCookie = exports.deleteCookie = exports.getCookie = exports.setCookie = void 0;
var setCookie = function (key, value, validForDays) {
    if (validForDays === void 0) { validForDays = 0; }
    var expires = '';
    if (validForDays) {
        var date = new Date();
        date.setTime(date.getTime() + validForDays * 24 * 60 * 60 * 1000);
        expires = "; expires=".concat(date.toUTCString());
    }
    document.cookie = "".concat(key, "=").concat(value).concat(expires, "; SameSite=None; Secure");
};
exports.setCookie = setCookie;
var getCookie = function (key) {
    var cookies = decodeURIComponent(document.cookie).split(';');
    var keyEq = key + '=';
    for (var _i = 0, cookies_1 = cookies; _i < cookies_1.length; _i++) {
        var cookie = cookies_1[_i];
        cookie = cookie.trim();
        if (cookie.startsWith(keyEq)) {
            return cookie.substring(keyEq.length, cookie.length);
        }
    }
    return undefined;
};
exports.getCookie = getCookie;
var deleteCookie = function (key) {
    document.cookie = "".concat(key, "=; expires=Thu, 01 Jan 1970 00:00:00 UTC");
};
exports.deleteCookie = deleteCookie;
var updateLastReachedStepInCookie = function (cookieName, newLastReachedStep) {
    if (cookieName) {
        var cookieValue = (0, exports.getCookie)(cookieName);
        if (cookieValue) {
            var parsedValue = void 0;
            try {
                parsedValue = JSON.parse(cookieValue);
            }
            catch (e) {
                console.error('Failed to parse cookie');
                return;
            }
            // Update only the lastReachedStep
            parsedValue.lastReachedStep = newLastReachedStep;
            (0, exports.setCookie)(cookieName, JSON.stringify(parsedValue), 21);
        }
    }
};
exports.updateLastReachedStepInCookie = updateLastReachedStepInCookie;
