"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageFormat = exports.ImageType = exports.ImageStatus = void 0;
var ImageStatus;
(function (ImageStatus) {
    ImageStatus["None"] = "none";
    ImageStatus["Fetching"] = "fetching";
    ImageStatus["Queued"] = "queued";
    ImageStatus["Ready"] = "ready";
    ImageStatus["Error"] = "error";
    ImageStatus["Timeout"] = "timeout";
})(ImageStatus = exports.ImageStatus || (exports.ImageStatus = {}));
var ImageType;
(function (ImageType) {
    ImageType["Image"] = "image";
    ImageType["Cubemap"] = "cubemap";
})(ImageType = exports.ImageType || (exports.ImageType = {}));
var ImageFormat;
(function (ImageFormat) {
    ImageFormat["PNG"] = "PNG";
    ImageFormat["JPEG"] = "JPEG";
})(ImageFormat = exports.ImageFormat || (exports.ImageFormat = {}));
