"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseUrl = exports.urlToConfig = exports.configToUrl = void 0;
var configToUrl = function (features) {
    return encodeURIComponent(btoa(JSON.stringify(features)));
};
exports.configToUrl = configToUrl;
var urlToConfig = function (url) {
    return JSON.parse(atob(decodeURIComponent(url)));
};
exports.urlToConfig = urlToConfig;
var getBaseUrl = function () {
    var market = document.referrer.includes('.ca') ? 'ca/en-ca' : 'us';
    var baseUrl = window.location != window.parent.location
        ? "https://www.volvotrucks.".concat(market, "/truck-builder")
        : "".concat(window.location.host, "/#/summary");
    return baseUrl;
};
exports.getBaseUrl = getBaseUrl;
