"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCachedJson = exports.getAllProducts = exports.getProduct = void 0;
var API_ONE_PRODUCT = process.env.API_ONE_PRODUCT || '';
var API_KEY = process.env.API_KEY || '';
var APPLICATION_ID = process.env.APPLICATION_ID || '';
var headers = new Headers();
headers.append('X-Header-AppId', APPLICATION_ID);
headers.append('X-Header-ApiKey', API_KEY);
headers.append('Content-Type', 'application/json');
var getProduct = function (id, locale) {
    return fetch("".concat(API_ONE_PRODUCT, "/").concat(id, "/data/").concat(locale), {
        headers: headers,
        method: 'GET'
    }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetProduct request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.getProduct = getProduct;
var getAllProducts = function (locale) {
    return fetch("".concat(API_ONE_PRODUCT, "/data/").concat(locale, "?ignoreComponents=true"), {
        headers: headers,
        method: 'GET'
    }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetAllProducts request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.getAllProducts = getAllProducts;
var getCachedJson = function (url) {
    return fetch(url, { headers: headers, method: 'GET' }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error('Failed to fetch cached JSON!'));
        }
        return response.json();
    });
};
exports.getCachedJson = getCachedJson;
