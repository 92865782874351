"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateEmail = exports.validateZip = exports.validateName = exports.validatePhone = void 0;
var validatePhone = function (value) {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
};
exports.validatePhone = validatePhone;
var validateName = function (value) {
    return /^[A-Za-z\s]*$/.test(value);
};
exports.validateName = validateName;
var validateZip = function (value) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)|([A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)/.test(value);
};
exports.validateZip = validateZip;
var validateEmail = function (value) {
    if (!value.includes('@') || !value.includes('.')) {
        return false;
    }
    return true;
};
exports.validateEmail = validateEmail;
