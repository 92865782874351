"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var useBezier = function (source, config) {
    var isStatic = (0, react_1.useContext)(framer_motion_1.MotionConfigContext).isStatic;
    var activeSpringAnimation = (0, react_1.useRef)(null);
    var value = (0, framer_motion_1.useMotionValue)(source);
    var stopAnimation = function () {
        if (activeSpringAnimation.current) {
            activeSpringAnimation.current.stop();
        }
    };
    (0, react_1.useInsertionEffect)(function () {
        return value.attach(function (v, set) {
            if (isStatic)
                return set(v);
            stopAnimation();
            activeSpringAnimation.current = (0, framer_motion_1.animateValue)(__assign(__assign({ keyframes: [value.get(), v], velocity: value.getVelocity(), type: 'tween', ease: [0.34, 0.27, 0.56, 0.98], duration: 550, restDelta: 0.001, restSpeed: 0.01 }, config), { onUpdate: set }));
            if (!framer_motion_1.frameData.isProcessing) {
                var delta = performance.now() - framer_motion_1.frameData.timestamp;
                if (delta < 30) {
                    activeSpringAnimation.current.time = delta / 1000;
                }
            }
            return value.get();
        }, stopAnimation);
    }, [JSON.stringify(config)]);
    return value;
};
exports.default = useBezier;
