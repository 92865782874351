"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMediaQuery = void 0;
var react_1 = __importStar(require("react"));
var vtna_component_library_1 = require("vtna-component-library");
var queries = (_a = {},
    _a[vtna_component_library_1.DeviceType.Mobile] = '(max-width: 767px)',
    _a[vtna_component_library_1.DeviceType.Tablet] = '(min-width: 768px)',
    _a[vtna_component_library_1.DeviceType.Desktop] = '(min-width: 960px)',
    _a[vtna_component_library_1.DeviceType.DesktopWide] = '(min-width: 1248px)',
    _a);
var useWindowWidth = function () {
    var _a = (0, react_1.useState)(0), windowWidth = _a[0], setWindowWdth = _a[1];
    (0, react_1.useEffect)(function () {
        var onResize = function () {
            setWindowWdth(window.innerWidth);
        };
        onResize();
        window.addEventListener('resize', onResize);
        return function () { return window.removeEventListener('resize', onResize); };
    }, []);
    return windowWidth;
};
var MediaQueryContext = react_1.default.createContext({
    matchedDevice: vtna_component_library_1.DeviceType.Mobile
});
var useMediaQuery = function () { return react_1.default.useContext(MediaQueryContext); };
exports.useMediaQuery = useMediaQuery;
/**
 * @category Context
 */
var MediaQueryProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)({ matchedDevice: vtna_component_library_1.DeviceType.Mobile }), value = _b[0], setValue = _b[1];
    var windowWidth = useWindowWidth();
    (0, react_1.useEffect)(function () {
        var newMatch = vtna_component_library_1.DeviceType.Mobile;
        for (var device in queries) {
            var match = window.matchMedia(queries[device]);
            if (match.matches) {
                newMatch = device;
            }
        }
        if (newMatch != value.matchedDevice) {
            setValue({ matchedDevice: newMatch });
        }
    }, [windowWidth]);
    return react_1.default.createElement(MediaQueryContext.Provider, { value: value }, children);
};
exports.default = MediaQueryProvider;
