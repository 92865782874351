"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sortModels = function (a, b) {
    if (a.product_info.name > b.product_info.name) {
        return -1;
    }
    if (b.product_info.name > a.product_info.name) {
        return 1;
    }
    return 0;
};
exports.default = sortModels;
